
enum PageType {
	DEFAULT = 0,
	PAGE_ONLY = 1,
}

export default class AppState {

	/** Type **/
	pageType: PageType = PageType.DEFAULT;

	/** Status **/
	isFullScreen: boolean = false;

	/** Modules **/
	// sidebar: PageWithSidebar = DependencyInjectorInstance().getInstance(PageWithSidebar.name);
	// navbar: PageWithNavbar = DependencyInjectorInstance().getInstance(PageWithNavbar.name);

	constructor() {
	}

	setPageDesignType(type: PageType) {
		// if (type === PageType.DEFAULT) {
		// 	this.navbar.vue.display = true;
		// 	this.sidebar.vue.display = true;
		// 	$("#gaia").addClass("gaia-dashboard-sidebar-left");
		// 	this.pageType = PageType.DEFAULT;
		// } else if (type === PageType.PAGE_ONLY) {
		// 	this.navbar.vue.display = false;
		// 	this.sidebar.vue.display = false;
		// 	$("#gaia").removeClass("gaia-dashboard-sidebar-left");
		// 	this.pageType = PageType.PAGE_ONLY;
		// }

	}

	/** View in fullscreen **/
	toggleFullScreen(): boolean {
		if (!this.isFullScreen) {
			let elem: any = document.documentElement;
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}
			this.isFullScreen = true;
			return true;
		} else {
			let doc: any = document;
			if (doc.exitFullscreen) {
				doc.exitFullscreen();
			} else if (doc.mozCancelFullScreen) { /* Firefox */
				doc.mozCancelFullScreen();
			} else if (doc.webkitExitFullscreen) { /* Chrome, Safari and Opera */
				doc.webkitExitFullscreen();
			} else if (doc.msExitFullscreen) { /* IE/Edge */
				doc.msExitFullscreen();
			}
			this.isFullScreen = false;
			return false;
		}
	}
}