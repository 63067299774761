import Config from "./config";
import AppState from "./controller/AppState";
import {Router} from "horizon-js-front-sdk/lib/Router";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Logger} from "horizon-js-front-sdk/lib/Logger";
import "fomantic/src/semantic.less";
import "fomantic/src/semantic.js"
import "horizon-js-front-sdk/lib/xdomain.min.js";
import "./libs/HorizonCss/horizon.css"
import "./assets/css/admin.css"
import "jquery";
import "./libs/tablesort.js"
import Cleave from "vue-cleave-component";
import Vue from "vue";
import AuthentificationManager from "./model/AuthenticationManager";

//setting logger on info
Logger.level = Logger.INFO;
let injector: DependencyInjector = DependencyInjectorInstance();

let config: Config = new Config();
injector.register(Config, config);

function initApp() {
	Vue.use(Cleave);

	injector.register(Config, config);

	let appState: AppState = new AppState();
	injector.register(AppState, appState);

	let router = new Router('/pages/', {
		type: 'URL',
		config: {
			suffixes: ['.html'],
			prefix: '/',
			registerGlobalInterceptor: true
		}
	}, 'page');

	let uuidRegex = /([0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12})/;

	router.addRoute({location: 'index', loader: () => import('./pages/index')});
	router.addRoute({location: 'auth', loader: () => import('./pages/auth')});
	router.addRoute({location: 'admin', loader: () => import('./pages/admin')});
	router.addRoute({location: 'configuration', loader: () => import('./pages/configuration')});
	router.addRoute({location: 'currencies', loader: () => import('./pages/currencies')});
	router.addRoute({location: 'devices', loader: () => import('./pages/devices')});
	router.addRoute({location: 'products', loader: () => import('./pages/products')});
	router.addRoute({location: 'profiles', loader: () => import('./pages/profiles')});
	router.addRoute({location: 'stats', loader: () => import('./pages/stats')});
	router.addRoute({location: 'transactions', loader: () => import('./pages/transactions')});
	router.addRoute({location: 'users', loader: () => import('./pages/users')});
	router.addRoute({location: 'wallets', loader: () => import('./pages/wallets')});

	DependencyInjectorInstance().register(Router, router);
	router.updateCurrentPageFromCurrentLocation();

	let button = document.getElementById('disconnect-button');
	if(button) {
		button.addEventListener('click', () => {
			AuthentificationManager.disconnect();
		});
	}
}

if (!config.isValid()) {
	config.getFromServer().then(function (JSONConfig: any) {
		config = new Config(JSONConfig);
		let xDomainParameter: any = {};

		let plDomain = config.cashlessApi_serviceUrl;
		if (plDomain) {
			let nddWithoutSlashAtTheEnd: string = '';
			if (plDomain.charAt(plDomain.length - 1) === '/') {
				nddWithoutSlashAtTheEnd = plDomain.substring(0, plDomain.length - 1);
			}
			xDomainParameter[nddWithoutSlashAtTheEnd] = "/api/cors";
		}
		xdomain.slaves(xDomainParameter);

		let storedVersion = window.localStorage.getItem('version');
		if (storedVersion !== null) {
			if (storedVersion !== config.version) {
				window.localStorage.setItem('version', config.version);
				window.location.reload(true);
			}
		} else {
			window.localStorage.setItem('version', config.version);
		}
		initApp();
	}).catch(function (error) {
		console.log("Error : Index.ts | Detail : ", error);
	});
} else {
	initApp();
}












