
//@TODO : rendre la class complètement indépendante au niveau des variales
import Config from "../config";
import {HorizonJs_Url} from "horizon-js-front-sdk/lib/Url";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";

export default class AuthentificationManager {
	static isConnected() {
		return (
			window.localStorage.getItem('oauth-token') !== null &&
			window.localStorage.getItem('oauth-expires_at') !== null &&
			parseInt('' + window.localStorage.getItem('oauth-expires_at')) > (new Date()).getTime()
		);
	}

	//TODO: add params to avoid auth.hml when connection is required
	static connect(config: Config, params: any = null): boolean {
		if (HorizonJs_Url.getSearchParametersWithName('access_token') !== null) {
			let state: string = HorizonJs_Url.getSearchParameters()['state'];
			let accessToken: string | null = HorizonJs_Url.getSearchParametersWithName('access_token');
			let expireIn: number = parseInt('' + HorizonJs_Url.getSearchParametersWithName('expires_in'));
			let tokenType: string | null = HorizonJs_Url.getSearchParametersWithName('token_type');
			let expireAt: number = 0;


			if (window.localStorage.getItem('logged_at') !== null) {
				expireAt = expireIn * 1000 + parseInt('' + window.localStorage.getItem('logged_at'));
			}
			if (window.localStorage.getItem('oauth-state') === state && expireAt !== 0) {
				window.localStorage.setItem('oauth-token', tokenType + ' ' + accessToken);
				window.localStorage.setItem('oauth-expires_at', expireAt.toString());
				window.localStorage.removeItem('prefill_identifier');
				return true;
			} else {
				console.log('mismatch state');
				return false;
			}
		}

		//Regarde s'il n'est pas connecté
		if (!AuthentificationManager.isConnected()) {
			let state = '' + Math.random();
			console.log("here456", state);
			window.localStorage.setItem('oauth-state', state);

			let clientId = config.identityGateway_clientId;
			let redirectUri = config.pl_serviceUrl + 'auth.html';

			let prefillIdentifier = window.localStorage.getItem('prefill_identifier');

			let currentDate = (new Date()).getTime();
			window.localStorage.setItem('logged_at', currentDate.toString());
			let loginPath: string = config.identityGateway_serviceUrl + 'api/v1/oauth2/authorize';
			let uriOauth =
				loginPath + '?'
				+ 'response_type=token'
				+ '&state=' + state
				+ '&client_id=' + clientId
				+ '&redirect_uri=' + encodeURIComponent(redirectUri)
				+ (prefillIdentifier ? ('&prefill_identifier=' + prefillIdentifier) : '')
			;

			// console.log(uriOauth);
			window.location.replace(uriOauth);

			return false;
		} else {
			let state = '' + Math.random();
			console.log("here456", state);
			window.localStorage.setItem('oauth-state', state);

			let clientId = config.identityGateway_clientId;
			let redirectUri = config.pl_serviceUrl + 'auth.html';

			let prefillIdentifier = window.localStorage.getItem('prefill_identifier');

			let currentDate = (new Date()).getTime();
			window.localStorage.setItem('logged_at', currentDate.toString());
			let loginPath: string = config.identityGateway_serviceUrl + 'api/v1/oauth2/authorize';
			let uriOauth =
				loginPath + '?'
				+ 'response_type=token'
				+ '&state=' + state
				+ '&client_id=' + clientId
				+ '&redirect_uri=' + encodeURIComponent(redirectUri)
				+ (prefillIdentifier ? ('&prefill_identifier=' + prefillIdentifier) : '')
			;

			// console.log(uriOauth);
			// window.location.replace(uriOauth);

			return false;
		}

		return false;
	}

	static getAuthentificationHeader(): string | null {
		// console.log("getAuthentificationHeader()")
		if (AuthentificationManager.isConnected()) {
			return window.localStorage.getItem('oauth-token');
		} else {
			return null;
		}
	}

	static getUserUid(): string {
		let oauthToken = window.localStorage.getItem('oauth-token');
		if (oauthToken !== null) {
			return HorizonJs_Url.parseJwt(oauthToken).userId;
		} else {
			return '';
		}
	}

	public static disconnect() {
		let injector: DependencyInjector = DependencyInjectorInstance();
		let config:any = injector.getInstance(Config);

		window.localStorage.removeItem('partner_establishment');
		window.localStorage.removeItem('user_partner_identities');
		window.localStorage.removeItem('gaia_current_establishement');
		window.localStorage.removeItem('identity_resource');
		if (config.identityGateway_serviceUrl) {
			AuthenticationManager.disconnect(config.identityGateway_serviceUrl + 'api/', config.pl_serviceUrl);
		}
	}

	static setCurrentEstablishment(establishmentUid: string | null) {
		if (establishmentUid === null)
			window.localStorage.removeItem('partner_establishment');
		else
			window.localStorage.setItem('partner_establishment', establishmentUid);
	};

	static setCurrentPartnerPermissions(permissions: any) {
		if (permissions === null)
			window.localStorage.removeItem('partner_permissions');
		else
			window.localStorage.setItem('partner_permissions', JSON.stringify(permissions));
	};

	static setUserEstablishments(partners: any) {
		if (partners === null)
			window.localStorage.removeItem('user_partner_identities');
		else
			window.localStorage.setItem('user_partner_identities', JSON.stringify(partners));
	};

}
