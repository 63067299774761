export default class Config {

	version!: string;

	cashlessApi_serviceUrl!: string;
	pl_serviceUrl!: string;
	pl_serviceName!: string;

	identityGateway_serviceUrl!: string;
	identityGateway_clientId!: string;
	identityGateway_apiUrl!: string;

	uri!: { "qrCode": string }

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Config.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === "uri") {
						instance[prop] = JSON.parse(json[prop]);
					} else {
						instance[prop] = json[prop];
					}
				}
			}
		}
	}

	getFromServer(url: string = 'config.json'): Promise<any> {
		let request: any = {
			url: url,
			method: 'GET',
			headers: {}
		};
		return new Promise(function (resolve, reject) {
			$.ajax(request).done(function (returnData: any) {
				//console.log("returnData",returnData);
				resolve(returnData);
			}).fail(function (xhr: any, status: any, error: any) {
				reject();
			});
		});
	}

	isValid(): boolean {
		return this.pl_serviceName !== undefined &&
			this.pl_serviceUrl !== undefined &&
			this.cashlessApi_serviceUrl !== undefined;
	}


}